<template>
  <div class="sign">
    <Popup
      :value="show"
      position="bottom"
      :overlay-style="{ opacity: 0.4 }"
      :style="{ minHeight: '400px' }"
      round
      :close-on-click-overlay="false"
      :lock-scroll="true"
      :lazy-render="true"
      :close-on-popstate="true"
      :safe-area-inset-bottom="true"
      @opened="handleInitCanvas">
      <img :src="close" alt="" class="close" @click="handleClosePop" />
      <div class="sign-title">绘制签名</div>
      <div class="sign-body">
        <div class="sign-tip">请用手指在框中绘制签名</div>
        <div class="sign-canvas">
          <canvas
            id="sign"
            ref="sign"
            @touchstart.capture="handleTouchStart"
            @touchmove.capture="handleTouchMove"
            @touchend.capture="handleTouchEnd"></canvas>
        </div>
      </div>
      <div class="sign-btns">
        <Button round class="clear" @click="handleClear">清除</Button>
        <Button
          round
          class="submit"
          type="info"
          :loading="isSubmit"
          loading-text="加载中..."
          @click="handleSubmit">
          <img class="sign-loading" slot="loading" src="../assets/images/loading.svg" alt="" />
          提交
        </Button>
      </div>
    </Popup>
  </div>
</template>

<script>
import close from '@/assets/images/close.svg'
import { Button, Popup, Toast } from 'vant'
import { convertBase64UrlToImgFile } from '../tool'
export default {
  props: {
    show: Boolean,
  },
  components: {
    Button,
    Popup,
  },
  data() {
    return {
      close,
      isSubmit: false,
      canvas: null,
      ctx: null,
      dpr: null,
      hasSign: false,
    }
  },
  methods: {
    handleInitCanvas() {
      const canvas = document.getElementById('sign')
      const ctx = canvas.getContext('2d')
      const dpr = window.devicePixelRatio
      canvas.width = canvas.offsetWidth * dpr
      canvas.height = canvas.offsetHeight * dpr
      ctx.lineCap = 'round'
      ctx.lineJoin = 'round'
      ctx.strokeStyle = '#000000'
      ctx.lineWidth = 3 * dpr
      this.canvas = canvas
      this.dpr = dpr
      this.ctx = ctx
    },
    handleTouchStart(e) {
      e.preventDefault()
      const { clientX } = e.changedTouches[0]
      const { clientY } = e.changedTouches[0]
      const { x, y } = this.canvas.getBoundingClientRect()
      const posX = clientX - x + 0.5
      const posY = clientY - y + 0.5
      this.ctx.beginPath()
      this.ctx.moveTo(posX * this.dpr, posY * this.dpr)
    },
    handleTouchMove(e) {
      e.preventDefault()
      this.hasSign = true
      const { clientX } = e.changedTouches[0]
      const { clientY } = e.changedTouches[0]
      const { x, y } = this.canvas.getBoundingClientRect()
      const posX = clientX - x + 0.5
      const posY = clientY - y + 0.5
      this.ctx.lineTo(posX * this.dpr, posY * this.dpr)
      this.ctx.stroke()
      this.ctx.moveTo(posX * this.dpr, posY * this.dpr)
    },
    handleTouchEnd() {
      this.ctx.closePath()
    },
    handleClear() {
      const { width, height } = this.canvas.getBoundingClientRect()
      this.ctx.clearRect(0, 0, width * this.dpr, height * this.dpr)
      this.hasSign = false
    },
    handleClosePop() {
      this.handleClear()
      this.$emit('update:show')
    },
    async handleSubmit() {
      if (this.hasSign) {
        const imgUrl = await this.canvas.toDataURL('image/png', 1.0)
        const fileName = new Date().getTime() + '.png'
        const imgFile = await convertBase64UrlToImgFile(imgUrl, fileName)

        this.handleUploadSign(imgFile)
      } else {
        Toast('请先书写您的签名')
      }
    },
    handleUploadSign(imgFile) {
      const loading = Toast.loading({
        duration: 2000, // 持续展示 toast
        forbidClick: true,
        message: '合同签署中...',
      })
      let formData = new FormData()
      formData.append('file', imgFile)
      formData.append('contractCode', this.$route.query.contractId)
      this.$http
        .post('/front/onlineContract/uploadSignImgNewAbroad', formData, {
          headers: {
            Accept: 'multipart/form-data',
          },
        })
        .then(res => {
          if (res.code === 1) {
            setTimeout(() => {
              loading.clear()
              this.$emit('confirm', res)
            }, 1500)
          } else {
            loading.clear()
            Toast({
              icon: 'clear',
              message: res.message,
              duration: 2000,
            })
          }
        })
        .catch(err => {
          loading.clear()
          console.log(err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep.sign {
  position: fixed;
  z-index: 15;
  font-size: 16px;
  .close {
    display: block;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    position: absolute;
  }

  @keyframes rotate {
    from {
      transform: rotateZ(-180deg);
    }
    to {
      transform: rotateZ(180deg);
    }
  }
  &-loading {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    animation: rotate 1s linear infinite;
  }
  &-title {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  &-body {
    width: 100%;
    background: #f7f7f7;
    padding: 0 24px 35px 24px;
  }
  &-tip {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  &-canvas {
    width: 100%;
    height: 190px;
    background: #ececec;
    border-radius: 6px;
    overflow: hidden;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
  &-btns {
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    .van-button {
      width: 155px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      border: none;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 1px;
    }
    .clear {
      background: #b5bdc9;
      box-shadow: 0px 2px 6px 0px rgba(201, 201, 201, 0.41);
    }
    .submit {
      background: #3276ff;
      box-shadow: 0px 2px 5px 0px rgba(121, 147, 197, 0.6);
    }
  }
}
</style>
