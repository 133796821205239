<template>
  <div class="index">
    <div class="index-title">合同详情</div>
    <Loading class="index-loading" v-if="current != total && showLoading" color="#3276ff" vertical
      >合同加载中...</Loading
    >
    <div id="m-pdf">
      <div id="pdf-content"></div>
    </div>
    <!-- <div class="index-list">
      <div class="index-pdf" v-for="i in numPages" :key="i">
        <PDF :src="src" :page="i" @num-pages="total = $event" @page-loaded="current = $event"></PDF>
        <div class="unable" v-if="validStatus == 6">已失效</div>
      </div>
    </div> -->
    <div class="index-btns">
      <router-link
        class="go-pay"
        v-if="signStatus === 1 && validStatus != 6 && isPayOnline === '1'"
        :to="{ path: '/contractPayList', query: { contractId: contractId } }"
        >立即支付</router-link
      >
      <img
        src="../assets/images/sign-btn.svg"
        v-if="signStatus === 0 && validStatus !== 6 && checked"
        alt=""
        @click="showSign = true" />
    </div>
    <Popup
      v-model="hasSign"
      round
      lock-scroll
      lazy-render
      :close-on-click-overlay="false"
      :overlay-style="{ opacity: 0.4 }"
      class="success-pop">
      <div class="index-success">
        <img src="../assets/images/success.svg" alt="" />
        <span>签署成功</span>
      </div>
    </Popup>
    <Checkbox
      style="margin-top: 10px"
      v-if="signStatus === 0 && isShowCheckbox && validStatus != 6"
      id="NewSignAture"
      class="read"
      v-model="checked"
      shape="square"
      icon-size="14px"
      @change="cahngeStatus"
      >以上内容我已阅读并知晓</Checkbox
    >
    <NewSignAture :show.sync="showSign" @confirm="handleCompleteSign"></NewSignAture>
    <PDF></PDF>
  </div>
</template>

<script>
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
import PDF from 'vue-pdf'
import { Loading, Popup, Toast, Checkbox } from 'vant'
import NewSignAture from '@/components/newSignAture'
export default {
  name: 'newContractDetails',
  components: { Loading, PDF, Popup, NewSignAture, Checkbox },
  data() {
    return {
      contractId: this.$route.query.contractId,
      src: '',
      numPages: '',
      current: 1,
      total: 0,
      hasSign: false,
      showSign: false,
      showLoading: false,
      isPayEnd: 1,
      validStatus: null,
      signStatus: null,
      flag: true,
      isPayOnline: '',
      checked: false,
      isShowCheckbox: false,
      totalPage: 0,
      pdfh5: null,
      height: null,
      scorllEnd: false,
    }
  },
  created() {
    if (!this.$route.query.contractId || this.$route.query.contractId == 'undefined') {
      Toast({
        message: '缺少合同编号',
        icon: 'warning',
        duration: 2000,
      })
      return false
    }
    this.handleGetContract()
  },
  methods: {
    initPdf(pdfUrl) {
      if (this.pdfh5) {
        this.pdfh5.destroy()
      }
      // pdfh5实例化时传两个参数：selector选择器，options配置项参数，会返回一个pdfh5实例对象，可以用来操作pdf，监听相关事件
      // pdfh5 = new Pdfh5(selector, options) goto初始到第几页，logo设置每一页pdf上的水印
      this.pdfh5 = new Pdfh5('#pdf-content', {
        pdfurl: pdfUrl,
        goto: 0,
        scale: 3,
        zoomEnable: false,
        scrollEnable: true,
        lazy: false,
        // 设置每一页pdf上的水印
        logo: { src: require("@/assets/images/logo_new.png"), x: 10, y: 12, width: 150, height: 55 },
      })
      this.pdfh5.on('render', (a, b, c) => {
        this.isShowCheckbox = true
        this.showLoading = false
        this.height = c.offsetHeight
        if (this.validStatus === 6) {
          c.innerHTML +=
            '<div style="  width: 100%;color: rgba(152, 152, 158, 0.25);font-size: 50px;position: absolute;top: 40%;left: 0;text-align: center;transform: rotateZ(30deg);font-weight: bold;letter-spacing: 2px;z-index:999999">已失效</div>'
        }
      })
      this.pdfh5.on('scroll', scrollTop => {
        if (this.height && !this.scorllEnd && scrollTop > this.height * (this.pdfh5.totalNum - 1)) {
          this.scorllEnd = true
        }
      })
    },

    handleGetContract() {
      this.$http
        .get('/front/viewContractPdf', { contractCode: this.$route.query.contractId })
        .then(res => {
          if (res.code == 1) {
            this.showLoading = true
            // this.handleLoadPDF(res.data.url)
            // this.src = res.data.url
            this.initPdf(res.data.url)
            this.signStatus = res.data.signStatus
            this.validStatus = res.data.validStatus
            this.isPayOnline = res.data.isPayOnline
          } else {
            Toast({
              icon: 'clear',
              forbidClick: true,
              message: res.message,
              duration: 2000,
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async handleLoadPDF(url) {
      this.src = await PDF.createLoadingTask(url)
      this.src.promise.then(res => {
        this.isShowCheckbox = true
        this.numPages = res.numPages
      })
    },
    handleClose() {
      this.showSign = false
    },
    handleCompleteSign() {
      this.showSign = false
      this.hasSign = true
      setTimeout(() => {
        this.hasSign = false
        this.handleGetContract()
      }, 1500)
    },
    singName() {
      if (!this.checked) {
        Toast({
          message: '请先阅读完合同内容，并勾选已知晓',
          icon: 'warning',
          duration: 2000,
        })
        // const child = document.getElementById(`app`)
        // child.scrollTop = 3800
      } else {
        this.showSign = true
      }
    },
    cahngeStatus(val) {
      if (!this.scorllEnd) {
        Toast({
          message: '请先阅读完合同内容，并勾选已知晓',
          icon: 'warning',
          duration: 2000,
        })
        this.checked = false
      } else {
        if (val) {
          this.checked = val
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .pdfjs .pdfViewer {
  padding: 0;
}
/deep/ .backTop {
  display: none !important;
}
.index {
  width: 100%;
  min-height: 100%;
  padding: 0 12px;
  background: #f3f4f7;
  background-image: url('../assets/images/contract-bg.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  #m-pdf {
    border-radius: 7px;
    #pdf-content {
      border-radius: 7px;
      .unable {
        width: 100%;
        color: rgba(152, 152, 158, 0.25);
        font-size: 50px;
        position: absolute;
        top: 40%;
        left: 0;
        text-align: center;
        transform: rotateZ(30deg);
        font-weight: bold;
        letter-spacing: 2px;
      }
    }
  }
  .success-pop {
    transform: translate(-50%, -70%);
  }
  &-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 73px;
    img {
      display: block;
      width: 64px;
      height: 64px;
      margin-bottom: 12px;
    }
    span {
      display: block;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      white-space: nowrap;
    }
  }
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    padding: 25px 0;
  }
  &-list {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  &-pdf {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 9px;
    background: #ffffff;
    position: relative;
    &:first-of-type {
      span {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
      }
    }
    &:last-of-type {
      span {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
      }
    }
  }
  &-loading {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }
  &-btns {
    position: fixed;
    bottom: 34px;
    left: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .go-pay {
      width: 134px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      background: #3276ff;
      box-shadow: 0px 2px 5px 0px rgba(121, 147, 197, 0.6);
      border-radius: 28px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    img {
      display: block;
      width: 52px;
      height: 52px;
      border-radius: 52px;
      background: #3276ff;
      box-shadow: 0px 2px 5px 0px rgba(121, 147, 197, 0.6);
    }
  }
  .read {
    position: relative;
    bottom: 0px;
    left: 50%;
    width: 180px;
    font-size: 14px;
    transform: translateX(-90px);
  }
}
</style>
